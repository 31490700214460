import { navigate, graphql, StaticQuery } from 'gatsby';
import React from 'react';

import phpScriptsUrl from '../../phpScriptsUrl';
import Layout from '../components/layout';
import { Content, Footer, FooterLinkBack, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import SvgArrowLeft from '../svg/arrowLeft';
import getString from '../utils/get-string';

class NotConfirmed extends React.Component {
  state = { emailResended: false }

  componentDidMount() {
    if (!this.props.location.state) return navigate(`/register`)
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  resendConfirmationEmail = () => {
    const userData = {
      ...this.props.location.state,
      baseUrl: `${this.props.location.protocol}//${this.props.location.host}`,
      confirmationUrl: '/email-confirmation',
    }

    window
      .fetch(`${phpScriptsUrl}email-confirmation.php`, {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
        body: this.objectEncode(userData),
      })
      .then(() => {
        if (this._isMounted)
          this.setState({
            emailResended: true,
          })
      })
  }

  objectEncode = obj => {
    var str = []
    for (var p in obj)
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    return str.join('&')
  }

  render() {
    return (
      <Layout>
        <Seo
          title={getString(this.props.strings, "11255164")}
          description={getString(this.props.strings, "11255172")}
        />

        <Page type={this.state.emailResended ? 'success' : 'error'}>
          <Header closeUrl="/lessons">{getString(this.props.strings, "11220916")} </Header>
          <Content center={true}>
            {!this.state.emailResended ? (
              <>
                <h1 className="h2 growing">{getString(this.props.strings, "11255164")}</h1>
                <div className="page-text-container">
                  <p className="h6">
                    {getString(this.props.strings, "11255172")}
                  </p>
                </div>
              </>
            ) : (
              <>
                <h1 className="h2 growing">{getString(this.props.strings, "11255181")}</h1>
                <div className="page-text-container">
                  <p className="h6">
                    {getString(this.props.strings, "11255172")}
                  </p>
                </div>
              </>
            )}
          </Content>
          <Footer>
            {this.state.emailResended ? (
              <FooterLinkNext to="/login">{getString(this.props.strings, "11255182")}</FooterLinkNext>
            ) : (
              <>
                <p className="h6">{getString(this.props.strings, "11255184")}</p>
                <FooterLinkNext onClick={() => this.resendConfirmationEmail()}>
                  {getString(this.props.strings, "11255396")}
                </FooterLinkNext>
                <FooterLinkBack to="/login">
                  <SvgArrowLeft />
                  {getString(this.props.strings, "11245158")}
                </FooterLinkBack>
              </>
            )}
          </Footer>
        </Page>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11255164", "11255172", "11220916", "11255181", "11255182", "11255184", "11255396", "11245158"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <NotConfirmed {...data} {...props} />}
  />
)
